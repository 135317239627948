.content {
    width: 50%;
    margin: 0 auto;
}

.select-group {
    margin-top: 40px;
    display: flex;
    text-align: center;
    justify-content: space-between;
}

.template-input{
    margin-bottom: 30px;
}

.header-name {
    margin-top: 30px;
    margin-bottom: 30px;
}

.label-name {
    margin-bottom: 8px;
    font-weight: bold;
}
.social-distance {
  margin-left: 10px;
  font-weight: bold;
}

.search-field {
  width: 300px;
}

.search-top-row {
  width: 645px;
  margin: 10px auto;
  display: flex;
  align-content: center;
  justify-content: space-between;
}

.shopping-cart-area {
  margin-bottom: 30px;
  display: flex;
  justify-content: flex-end;
  width: 675px;
}

.pagination-btn-container {
  margin-left: 15px;
}

.gave-to-filter {
  margin-left: 55px;
  margin-top: 5px;
}

div.gave-to-filter
  > button.ui.blue.active.basic.compact.toggle.button.btn-gave-in-the-past {
  background-color: #edf5ff !important;
}

.search-results-text {
  margin-left: 5px;
  color: #737373;
  font: normal normal normal 14px/16px Lato;
}

.search-area-with-match-display {
  height: 60px;
}

.btn-shopping-cart {
  text-align: center;
  font: normal normal bold 14px/17px Lato;
  letter-spacing: 0px;
  color: #ffffff !important;
}

.badge {
  position: relative;
  top: -10px;
  left: -15px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  background: #2185d0;
  color: white;
}

.pagination-buttons {
  width: 640px;
  display: flex;
  justify-content: center;
  align-content: center;
}

.page-x-of-y {
  padding: 10px;
  font: normal normal normal 14px/11px Lato;
}

.btn-next {
  width: 66px;
  height: 30px;
  text-align: center;
  font: normal normal bold 14px/17px Lato;
  padding-right: 0px !important;
  padding-left: 0px !important;
  margin-left: 10px !important;
}

.btn-previous {
  width: 86px;
  height: 30px;
  text-align: center;
  font: normal normal bold 14px/17px Lato;
  padding-right: 0px !important;
  padding-left: 0px !important;
  margin-right: 10px !important;
}

.disabled.btn-previous,
.disabled.btn-next {
  background-color: #ececec !important;
  color: #8b8b8b !important;
}

.link-clear-filter {
  cursor: pointer;
  margin-left: 10px;
  color: #2185d0;
}

.search-screen {
  width: 600px;
  margin: 0 auto;
}

button.ui.blue.basic.button.add-new-customer {
  text-align: center;
  line-height: 30px;
  margin-top: 30px;
  margin-bottom: -30px;
  display: block;
  padding: 5px 10px;
}

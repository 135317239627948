.new-template-screen {
  width: 600px;
  margin: 0 auto;
  padding-top: 50px;
  padding-bottom: 100px;
}

.form-template {
  margin-top: 30px;
  padding-top: 30px;
}

.button-create {
  display: flex;
  justify-content: space-around;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px ;
}

.upload-file__input {
  opacity: 0;
  position: absolute;
  z-index: -1;
  overflow: hidden;
  width: 0.4px;
  height: 0.4px;
}

.upload-file__label {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #150B22;
  padding: 9px 49px;
  border-radius: 6px;
  cursor: pointer;
  margin-bottom: 33px;
}

.upload-file__icon {
  width: 30px;
  height: auto;
  margin-right: 11px;
}

.upload-file__label .upload-file__text,
.upload-file__label .upload-file__icon path {
  transition: .25s ease;
  font-weight: bold;
  color: #2d5486;
}

.upload-file__label:hover .upload-file__text {
  font-weight: bold;
  color: #2d5486;
}

.upload-file__label:hover .upload-file__icon path {
  fill: #2d4686;
}

.upload-file__label:hover {
  border: 2px dashed #3070e5;
}

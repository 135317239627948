.address-suggestions {
  list-style-type: none;
  margin-top: -1px;
  width: 92%;
  border: 1px solid gray;
  border-radius: 3px;
}

ul.address-suggestions > li {
  padding: 10px;
  padding-left: 7px;
  margin-left: -40px;
}

ul.address-suggestions > li:hover,
ul.address-suggestions > li:active,
ul.address-suggestions > li:focus,
ul.address-suggestions > li.active {
  background: rgb(216, 210, 210);
  border: 1px solid gray;
}

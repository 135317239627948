.switch-label {
  display: block;
  margin-top: -27px;
  margin-left: 57px;
  color: #172b4d;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
}

.two-column-container-switch {
  width: 100%;
  margin-left: 10px;
  margin-bottom: 15px;
  display: grid;
  grid-template-columns: 52% 48%;
}

.two-column-container-checkboxes {
  width: 100%;
  margin-left: 10px;
  margin-bottom: 15px;
  display: grid;
  grid-template-columns: 52% 48%;
}

.heading {
  color: #6b778c;
  font-family: Lato;
  font-size: 16px;
  letter-spacing: 0;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
}

.default-greeting-container {
  width: 95%;
  display: flex;
  flex-direction: column;
}

.default-greeting-label {
  color: #6b778c;
  font-family: Lato;
  font-size: 14px;
  letter-spacing: 0;
  font-weight: bold;
  letter-spacing: 0;
  margin-top: 10px;
}

textarea.default-greeting-text {
  margin-top: -10px;
}

hr.section-separator {
  border: 0;
  height: 1px;
  background: #dfe1e5;
  background-image: linear-gradient(to right, #ccc, #333, #ccc);
  width: 100%;
}

.checkboxes-container {
  display: flex;
  flex-direction: column;
}

.ui.checkbox.ez-checkbox {
  background-color: #fafbfc;
  margin-top: 15px;
  margin-left: 10px;
}

.staff-container {
  display: flex;
  justify-content: space-between;
}

.action-btn-container {
  display: flex;
  justify-content: space-between;
}

.ui.basic.button.ez-basic-btn {
  font-weight: bold;
}

textarea.default-greeting-text.error,
textarea.default-greeting-text.error:active,
textarea.default-greeting-text.error:focus {
  border: 2px solid #de350b;
  outline: 0;
}
.button-container{
  display: flex;
  text-align: center;
  justify-content: space-around;
  margin: 29px;
}
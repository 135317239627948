.content-table-orders {
    width: 70%;
    margin: 0 auto;
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
}

.select-group{
    /* margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    text-align: center;
    justify-content: space-between; */
    margin-bottom: 40px;
    position: relative;
}
.select-left {
    position: absolute;
    bottom: 0;
    left: 0;
}
.select-right {
    position: absolute;
    bottom: 0;
    right: 0;
}
.sort-select{
    display: flex;
    text-align: center;
    justify-content: space-between;

}
.search{
    margin-left: 10px;
}
.button-download-item{
    margin-left: 400px; 
    margin-top: 35px;
    margin-bottom: 25px;
    text-align: right;
  }
.title {
    padding: 40px;
    text-align: center;
}
.tableRow:hover {
    cursor: pointer;
}

.table-order-row {
    border-collapse: collapse;
}
.highlight {
  background: yellow;
  transition: opacity 3s;
  -webkit-animation: 3s ease 0s normal forwards 1 fadein;
  animation: 3s ease 0s normal forwards 1 fadein;
}

@keyframes fadein {
  0% {
    opacity: 0.2;
  }
  66% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0.2;
  }
  66% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

.selected {
  background: #76767614 0% 0% no-repeat padding-box;
  border: 1px solid #e2e2e3;
}

.record-table-row {
  width: fit-content;
}

.name-inside-cell {
  text-align: left;
  font: normal normal bold 16px/24px Lato;
  letter-spacing: 0px;
  color: #4a4a4a;
}

.address-inside-cell {
  text-align: left;
  font: normal normal normal 14px/16px Lato;
  letter-spacing: 0px;
  color: #737373;
}

.add-remove-customer {
  display: flex;
  align-content: center;
  justify-content: space-between;
}

.add-remove-customer .two-column-container {
  margin-left: 10px;
}

.btn-remove-from-cart {
  font-size: 11px !important;
}

.btn-add-to-cart {
  font-size: 14px !important;
}

.btn-remove-from-cart,
.btn-add-to-cart {
  width: 50px;
  height: 30px;
  text-align: center !important;
  font: normal normal normal 11px/13px Lato;
  letter-spacing: 0px;
  padding: 0 !important;
  margin-top: auto !important;
}

.name-address-content {
  margin-left: 10px;
}

.green-check {
  margin-top: 10px;
  margin-left: auto;
}

.green-check-icon {
  color: #21ba45;
}

.ui.blue.basic.cancel-btn {
  width: 80px;
  margin-top: 10px;
  margin-left: 20px;
}

.ui.primary.button.save-btn {
  width: 80px;
  margin-top: 10px;
  margin-left: 20px;
}

.save-cancel-container {
  margin-top: 10px;
  margin-left: 20px;
  position: sticky;
  top: 20px;
  left: 0;
}

.selected {
  background: #737373;
}

.pointer {
  cursor: pointer;
}

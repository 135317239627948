.content-template {
    width: 55%;
    margin: 0 auto;
}

.button-download-order{
    margin-left: 75%;
    margin-top: 25px;
    margin-bottom: 25px;
}

.select-group{
    margin-top: 40px;
    display: flex;
    text-align: center;
    justify-content: space-between;
}
.merge-page-container {
  display: flex;
  gap: 15px;
}

.merge-container {
  flex-grow: 1;
  width: 50%;
}

.customers-dropdown,
.no-data {
  margin-top: 40px;
  margin-bottom: 14px;
  width: 50%;
}
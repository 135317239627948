input.ezinput {
  display: block;
  padding-left: 5px;
  margin-top: 5px;
  display: block;
  box-sizing: border-box;
  height: 40px;
  width: 229px;
  border: 2px solid #dfe1e5;
  border-radius: 3px;
  background-color: #fafbfc;
}

input.ezinput.error {
  border: 2px solid #de350b;
  outline: 0;
}

input.ezinput:disabled {
  border: none;
  background: #f4f5f7;
}

.help-text {
  margin-top: 7px;
  margin-left: 5px;
  height: 17px;
  width: 229px;
  color: #6b778c;
  font-family: Lato;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
}

.error-message-div {
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-left: 3px;
}

.error-message-div > img {
  margin-right: 5px;
  height: 14px;
  width: 14px;
}

.error-message {
  height: 16px;
  width: 218px;
  color: #de350b;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: -0.09px;
  line-height: 16px;
}

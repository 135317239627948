#page-content {
  margin: 0;
  height: calc(100vh - 4em);
}

#content {
  height: 100%;
  overflow: auto;
  width: calc(100% - 150px);
}

.content-box {
  min-height: 100%;
}

.hidden {
  display: none;
}

.ui.list, ol.ui.list, ul.ui.list {
  position: relative;
  margin: 0;
  padding: 10px 0 !important; 
}

.subList::before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: rgba(255,255,255,.08);
}

.subList::after {
  position: absolute;
  content: '';
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: rgba(255,255,255,.08);
}

.ui.menu .ui.list .list>.item, .ui.menu .ui.list>.item {
  padding: .3em;
}

.ui.menu .ui.list .list>.item, .ui.menu .ui.list>.item:hover {
  background: rgba(255,255,255,.08);
}

.ui.vertical.menu .item:before {
  display: none;
}

.SidebarPushable-Wrapper #page-content {
  border-radius: 0;
  border: 0;
}

.content-table {
    width: 600px;
    margin: 0 auto;
}

.title {
    text-align: center;
    padding: 20px;
}

.button-download {
    margin-left: 400px;
    margin-top: 25px;
}

.email-addresses {
    margin-top: 40px;
    display: flex;

    justify-content: space-between;
}

.delivery-button {
    height: 186px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.paper-orders {
    height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.report-segment {
    min-height: 100px;
}

.submit-button {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
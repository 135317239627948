.orders-item{
  margin-left: 12px;
  margin-bottom: 34px;
}
.close-button{
  margin-left: 330px;
  margin-top: 40px;
}
.orders-table {
  width: 70%;
}

.flex-container {
  display: flex;
}

.flex-child {
  flex: 1;
}  

.flex-child:first-child {
  margin-right: 20px;
  margin-bottom: 20px;
} 
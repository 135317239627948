.input-settings{
  width: 267px;
}

.button-download {
  margin-left: 400px;
  margin-top: 25px;
}
.content-table {
  width: 600px;
  margin: 0 auto;
}

.title {
  text-align: center;
  padding: 20px;
}

.button-save {
  margin-left: 400px;
  margin-top: 25px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
  height: 4em;
}

.logo-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
}

.page-title {
  font-size: x-large;
  margin-left: 15px;
  color: #000000;
}

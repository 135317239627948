.content-table-one {
    width: 80%;
    margin: 0 auto;
}

.pagination-greetings {
    display: flex;
    text-align: center;
    justify-content: center;
    margin-bottom: 50px;
    margin-top: 50px;
}

.select-group{
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    text-align: center;
    justify-content: space-between;
}

.checkmark-slider {
    display: flex;
    align-items: center;
}
.search{
    margin-left: 10px;
}
.check-unpaid-icon {
    color: #9f3a38;
}
.select-right {
    position: absolute;
    bottom: 0;
    right: 0;
}
.check-paid-icon {
    color: green;
}

/* .tableRow:hover {
    cursor: pointer;
} */

.table-order-row {
    border-collapse: collapse;
}

.name-cell {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}